/* eslint-disable no-shadow */

import excelToJson from "convert-excel-to-json";
import { flatten, sortBy } from "lodash";

export const state = () => ({
  allCards: [],
  cardNames: [],
  cardSelections: []
});

export const mutations = {
  setAllCards(state, cards) {
    state.allCards = cards;
  },
  setCardNames(state, cardNames) {
    state.cardNames = cardNames;
  },
  setCardSelections(state, cards) {
    state.cardSelections = cards;
  }
};

export const actions = {
  async getCardRanks(state) {
    // const rankingsFile = this.$axios.$get("static/draftguide-values.xlsx");
    const result = excelToJson({
      sourceFile: "static/draftguide-values.xlsx",
      header: {
        rows: 1
      },
      columnToKey: {
        C: "cardName",
        D: "faction",
        E: "grade",
        F: "rank",
        G: "note"
      }
    });
    this.dispatch("draftRankings/processCardList", result);
  },
  processCardList(state, cards) {
    const allCards = Object.keys(cards).map(card => cards[card]);
    const allCardsFlat = flatten(allCards);
    const allCardsRemoveEmpty = allCardsFlat.filter(
      card => card.cardName !== ""
    );
    const allCardsSorted = sortBy(allCardsRemoveEmpty, ["cardName"]);
    this.commit("draftRankings/setAllCards", allCardsSorted);
    const cardNames = allCardsSorted.map(card => card.cardName);
    this.commit("draftRankings/setCardNames", cardNames);
  }
};
