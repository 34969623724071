export const strict = false;

/* eslint-disable no-shadow */
export const state = () => ({
  // counter: 0
});

export const actions = {
  // nuxtServerInit is only called on the top level store and not namespaced
  // stores so call namespaced actions from here.
  async nuxtServerInit({ dispatch }, { app }) {
    return Promise.all([dispatch("draftRankings/getCardRanks")]);
  }
};
